import { STAGE_DESCRIPTION } from '~/functions/helpers';
export default defineComponent({
  props: {
    outcome: {
      type: Object,
      required: true
    }
  },
  setup: function setup() {},
  data: function data() {
    return {
      STAGE_DESCRIPTION: STAGE_DESCRIPTION
    };
  }
});